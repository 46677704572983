// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("@hotwired/turbo-rails")
require("channels")
require("typeface-merriweather")
require("typeface-poppins")
require("flatpickr/dist/flatpickr.css")

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
import "stylesheets/application"
import { Application } from "stimulus"
import "@hotwired/turbo-rails"
import { Turbo, cable } from "@hotwired/turbo-rails"
import Flatpickr from 'stimulus-flatpickr'
import PlacesAutocomplete from "stimulus-places-autocomplete"
import ReadMore from "stimulus-read-more"
import Reveal from "stimulus-reveal-controller"
import Clipboard from "stimulus-clipboard"
import Notification from "stimulus-notification"
import CharacterCounter from "stimulus-character-counter"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import $ from "jquery";
import Cookies from "cookies"
import LocalTime from "local-time"
import { Dropdown, Tabs } from "tailwindcss-stimulus-components"
import Swiper from "swiper"
import "swiper/swiper-bundle.min.css"
import ahoy from 'ahoy.js'
import { datadogRum } from "@datadog/browser-rum"
import tippy from "tippy.js"
import gtag from "analytics"

const application = Application.start()
LocalTime.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))
application.register("places", PlacesAutocomplete)
application.register("flatpickr", Flatpickr)
application.register("read-more", ReadMore)
application.register("reveal", Reveal)
application.register("tabs", Tabs)
application.register("dropdown", Dropdown)
application.register("clipboard", Clipboard)
application.register("notification", Notification)
application.register("character-counter", CharacterCounter)

if (process.env.NODE_ENV == "production") {
  datadogRum.init({
    applicationId: "d6254e80-7113-42eb-8390-1d94b894b118",
    clientToken: "pub4ba1d7d03a6732a20072f8d819aad38b",
    site: "us5.datadoghq.com",
    service: "bornbir-rails",
    env: "production",
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    allowedTracingUrls: ["https://www.bornbir.com", /https:\/\/.*\.bornbir\.com/, (url) => url.startsWith("https://www.bornbir.com")],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "allow",
  });

  datadogRum.startSessionReplayRecording();
  datadogRum.setUser({
    email: Cookies.get("email"),
  })
}

window.initAutocomplete = function () {
  const event = new Event("google-maps-callback", { bubbles: true, cancelable: true })
  window.dispatchEvent(event)
}

// Disabling enter key for form
$(document).on("keydown", ":input:not(textarea)", function(event) {
  return event.key != "Enter";
});

ahoy.trackClicks("#view_provider_phone_number");
